<template>
  <div>
    <app-card>
      <template #body>
        <app-table :request-url="`/api/act/table?task_id=${taskId}&with=company`" :fields="fields">
          <template #cell(company)="data">
            <router-link :to="`companies/${data.value.id}`" class="text-primary text-hover-success">
              {{ data.value.name }}
            </router-link>
          </template>
          <template #cell(master)="data">{{ data.value || '-' }}</template>
          <template #cell(assistant)="data">{{ data.value || '-' }}</template>
          <template #cell(error_description)="data">{{ data.value || '-' }}</template>
          <template #cell(job_description)="data">{{ data.value || '-' }}</template>
          <template #cell(description_by_master)="data">{{ data.value || '-' }}</template>
          <template #cell(document)="data">
            <a v-if="data.value" :href="data.value" target="_blank">{{ $t('label.view') }}</a>
            <span v-else>-</span>
          </template>
        </app-table>
      </template>
    </app-card>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable,
  },
  data() {
    return {
      taskId: null,
      filters: null,
      fields: [
        {key: 'master', label: this.$t('label.master')},
        {key: 'company', label: this.$t('label.company')},
        {key: 'assistant', label: this.$t('label.assistant')},
        {key: 'error_description', label: this.$t('label.error_description')},
        {key: 'job_description', label: this.$t('label.job_description')},
        {key: 'description_by_master', label: this.$t('label.description_by_master')},
        {key: 'document', label: this.$t('label.document')},
        {key: 'created_at', label: this.$t('label.created_at')},
        {key: 'arrival_at', label: this.$t('label.arrival_at')},
        {key: 'finished_at', label: this.$t('label.finished_at')}
      ]
    }
  },
  beforeMount() {
    this.taskId = this.$route.query.task_id;
  }
}
</script>